<template>
  <div>
    <van-nav-bar
      :title="withdrawResultTitle"
      left-text=""
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <div class="process">
      <div class="step">
        <div class="circle"></div>
        <div class="line"></div>
        <div class="circle" v-if="status == 'S'"></div>
        <!-- 第二阶段状态   -->
        <img
          class="processImg"
          src="assets/ic_Process.png"
          v-if="status == 'P'"
        />
        <!-- 第三阶段状态 -->
        <div
          class="line"
          style="border-color: #eff0f2; position: relative; top: -26px"
          v-if="status == 'P'"
        ></div>
        <div class="line" v-if="status == 'S'"></div>
        <div
          class="circle"
          v-if="status == 'P'"
          style="background: #eff0f2; position: relative; top: -26px"
        ></div>
        <!-- 到账成功终态 -->
        <img
          class="successImg"
          v-if="status == 'S'"
          src="assets/ic_success.png"
        />
      </div>
      <div class="processText">
        <div class="one">
          <div>{{ $t("withdrawResult.Request") }}</div>
          <div>{{ createTime }}</div>
        </div>
        <div class="two">
          <div class="boldFont" v-if="status == 'P'">
            {{ $t("withdrawResult.Processing") }}
          </div>
        </div>
        <div class="two" v-if="status == 'S'">
          <div>{{ $t("withdrawResult.Processing") }}</div>
        </div>
        <div class="three" v-if="status == 'S'">
          <div class="boldFont">{{ $t("withdrawResult.Success") }}</div>
          <div v-if="successTime != ''">{{ successTime }}</div>
        </div>
        <div class="three" v-if="status == 'P'">
          <div>{{ $t("withdrawResult.Success") }}</div>
        </div>
      </div>
    </div>
    <div class="moneyCont">
      <p>
        <span> {{ $t("withdraw.WithdrawalAmount") }}</span>
        <span class="money"> {{ amount }}  <span v-if="amount">{{ $t("withdrawResult.monetaryUnit") }} </span></span>
      </p>
      <p>
        <span> {{ $t("withdraw.Withdrawnto") }}</span>
        <span> {{ bankAccount }}</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  watch: {
    $route: "getPath",
  },
  data() {
    return {
      hasBack:false,
      fromPath: "",
      formType:'',
      status: "S",
      withdrawResultTitle: "",
      // S 成功 F 失败 P 处理中
      createTime: "2020-12-26 12:23:56",
      successTime: "2020-12-26 12:23:56",
      cashoutFailBoxshow: false,
      mengcengshow: false,
      amount: 30,
      bankAccount: "XXXX银行(2980)",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 通过 `vm` 访问组件实例,将值传入fromPath
      vm.fromPath = from.path;
    });
  },
  mounted() {
        this.formType = this.$route.params.fromPath;
    console.log(this.$route.params);
    console.log(this.formType);

    this.getPath();
     var that = this ;
      window.addEventListener('hashchange', function(e) {  
    if(!that.hasBack){
        const fromFullPath = e.oldURL.split('#')[1];
          if(fromFullPath =='/withdrawResult' && that.formType == "/recordDetail"){
            that.$router.replace("/record");
          }else if(fromFullPath =='/withdrawResult' && that.formType == "/home"){
            let backlen = window.history.length - 1;
            window.history.go(-backlen);
            that.$router.replace("/home");
          }
    }
      }, false);
  },
  methods: {
    getPath() {
      if (this.$route.path == "/withdrawResult") {
        console.log(this.formType);
        this.$nextTick(() => {
          // 验证是否获取到了上页的url
          /* eslint-disable no-console */
          console.log(this.fromPath);
        });
        this.withdrawResultTitle = this.$t("withdrawResult.title");
        this.amount = this.$route.params.amount;
        this.bankAccount = this.$route.params.bankAccount;
        this.createTime = this.$route.params.createTime;
        this.status = this.$route.params.status;
        this.successTime = this.$route.params.successTime;
        this.formType = this.$route.params.fromPath;
      }
    },
    closecashoutFailBox() {
      this.cashoutFailBoxshow = false;
      this.mengcengshow = false;
    },
    back(){
      this.hasBack = true;
      // 是否已经点按钮返回了
          if(this.formType == "/recordDetail"){
            this.$router.replace('/record' );
          }else{
            // location.reload();
            let backlen = window.history.length - 1;
            console.log('222--'+backlen);
            window.history.go(-backlen);
            this.$router.replace("/home");
          }
    }
  },
};
</script>
<style scoped>
.titleCont {
  margin-top: 46px;
  padding: 30px 24px;
}
.titleCont p:nth-child(1) {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-top: 0;
}
.titleCont p:nth-child(2) {
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #999999;
}
.moneyCont {
  padding: 16px 24px;
}
/* .moneyCont > p {
  margin: 0;
  line-height: 40px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.moneyCont > p > span:nth-child(1) {
  font-size: 16px;
  color: #999;
} */
.moneyCont p {
  margin: 0;
  line-height: 40px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.moneyCont > p > span:nth-child(1) {
  font-size: 16px;
  color: #999;
  display: inline-block;
    width: 145px;
}
.moneyCont > p > span:nth-child(2) {
  text-align: right;
      flex: 1;
}
.moneyCont > p:nth-child(2) {
  line-height: 28px;
    height: auto;
    margin-top: 5px;
}
.money {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.btn {
  line-height: 44px;
  color: #fff;
  text-align: center;
  width: 86%;
  height: 44px;
  left: 7%;
  font-size: 14px;
  bottom: 45px;
  background: #2dd070;
  border-radius: 12px;
  position: fixed;
}

.cashoutFailBox {
  box-sizing: border-box;
  position: fixed;
  top: calc(50vh - 74px);
  left: 8%;
  width: 280px;
  padding: 24px;
  background: #fff;
  height: auto;
  border-radius: 16px;
  text-align: center;
  width: 84%;
}
.cashoutFailBox p:nth-child(1) {
  margin: 0;
  margin-bottom: 20px;
  color: #333;
  font-size: 16px;
  line-height: 22px;
}
.cashoutFailBox p:nth-child(2) {
  margin-bottom: 20px;
  /* font-weight: bold; */
  font-size: 13px;
  line-height: 18px;
  color: #999;
}
.cashoutFailBox .textbtn {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2dc84e;
}
.process {
  display: flex;
  margin: 95px 32px 0 32px;
  border-bottom: 1px solid #eff0f2;
  padding-bottom: 8px;
}
.line {
  border: 1px solid #eff0f2;
}
.step {
  width: 32px;
  margin-left: 16px;
}
.circle {
  width: 9px;
  height: 9px;
  background: #2dd070;
  border-radius: 50%;
}
.line {
  width: 0;
  height: 56px;
  margin-left: 3px;
  border: 1px dashed #2dd070;
}
.one,
.two,
.three {
  font-family: PingFang SC;
  position: relative;
  top: -16px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #999999;
  margin-bottom: 38px;
}
.three {
  margin-bottom: 0;
}
.suc {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 2px;
}
.successImg {
  width: 32px;
  height: 32px;
  position: relative;
  left: -12px;
  top: -21px;
}
.processImg {
  width: 32px;
  height: 32px;
  position: relative;
  left: -12px;
  top: -10px;
}
.boldFont {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}
</style>